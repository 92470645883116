@mixin data-capture-page-theme($theme) {
    $primary: map-get($theme , $primary );
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    accrual, deferral, business-unit-adjustment, summary-action, business-unit-adjustment-transfer, miscellaneous-income {
        //Accrual related theming style changes should come here
        .error-msg {
            color: #f44336;
        }
        input[type='file'] {
            border: 1px solid #ccc;
        }
        .adc-remove-icon {
            margin-left: 0.5em;
            display: inline-flex;
            vertical-align: middle;
            cursor: pointer;
        }
    }
}