body {
    font-family: "Roboto","Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
    margin: 0;
  }
  
  .docs-button[md-button], .docs-button[md-raised-button] {
    text-transform: uppercase;
  }
  
  h1, h2 {
    font-weight: 400;
  }
  
  .docs-primary-header {
    padding-left: 20px;
  
    h1 {
      font-size: 30px;
      font-weight: 300;
      margin: 0;
      padding: 28px 8px;
      font-size: 20px;
    }

    .request-date, .period-date{
      padding-top: 28px;
      padding-bottom: 28px;
    }
  }
  
  code {
    font-size: 90%;
  }
  
  .docs-markdown-pre code {
    font-size: 100%;
  }
  
  // These styles are for controlling SVGs without using the /deep/ selector
  
  .docs-component-category-list-card-image svg {
    width: 100%;
  }
  
  .docs-footer-accelerator-logo svg {
    height: 50px;
  }
  
  .docs-accelerator-logo svg {
    height: 26px;
    margin: 0 4px 3px 0;
    vertical-align: middle;
  }

  .content-padding-24{
    padding: 24px;
}
  
//   .docs-accelerator-logo svg {
//     height: 21px;
//     margin: 0 7px 2px 0;
//     vertical-align: middle;
//   }