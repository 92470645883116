@import '../node_modules/@angular/material/theming';

@import './app/pages/home/_home-theme';
@import './app/shared/nav-bar/_nav-bar-theme';
@import './styles/_svg-theme';

@import './app/pages/adjustment/adjustment-theme';
@import './app/pages/data-capture/data-capture-theme';

// Styles for the docs app that are based on the current theme.
@mixin material-docs-app-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .docs-app-background {
    background: mat-color($background, background);
  }

  .docs-primary-header {
    //background: mat-color($primary);
    background: #262626;
    margin-top: 1em;
    h1 {
      //color: mat-color($primary, default-contrast);
      color: #47d7ac;
    }
    div.request-date, div.period-date{
      //color: mat-color($primary, default-contrast);
      color: #47d7ac;
    }
  }

  .docs-footer {
    background: mat-color($primary);
    color: mat-color($primary, default-contrast);
  }

  @include docs-site-svg-theme($theme);
  @include home-page-theme($theme);
  @include nav-bar-theme($theme);
  @include adjustment-page-theme($theme);
  @include data-capture-page-theme($theme);
}