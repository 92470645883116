@mixin nav-bar-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
  
    app-nav-bar {
      //color: mat-color($primary, default-contrast);
      color: #47d7ac;
  
      .docs-navbar, .docs-navbar-header {
        //background: mat-color($primary);
        background: #262626;
      }
    }
  }