@mixin home-page-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
  
    app-home {
      .docs-header-background {
        //background: mat-color($primary);
        background: #262626;
      }
  
      .docs-header-headline {
        //color: mat-color($primary, default-contrast);
        color: #47d7ac;
      }
  
      .docs-header-start {
        //color: mat-color($primary);
        color: #47d7ac;
      }
  
      .docs-homepage-row {
        color: mat-color($foreground, secondary-text);
      }
    }
  }