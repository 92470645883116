@mixin adjustment-page-theme($theme) {
    $primary: map-get($theme , $primary );
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    
    app-adjustment-list {
        //Making Table header styles in generic way throughout app.
        .mat-header-cell {
            background: #e4e1e1;
            font-weight: bold;
            color: #000;
        }
    }
}